import { TechnicalSpecification } from 'src/components/TechnicalSpecificationsList/TechnicalSpecificationsList';

const blockTextPredicate = (block: { selector?: string | null; paragraphs }) => block.paragraphs[0].children[0].text;

export function createTechnicalSpecificationsFromBlockContent(content: {
  selector?: string | null;
  //TODO: Make this ProductPageWebsiteContent if we remove the FAQ section from Main Page.
  Texts?:
    | {
        selector?: string | null;
        paragraphs: {
          _type?: string;
          children: {
            _type?: string;
            text: string;
          }[];
        }[];
      }[]
    | null;
}): TechnicalSpecification[] | undefined {
  const categories = content.Texts?.filter((text) => text.selector?.includes('specTitle')).map(blockTextPredicate);
  const descriptions = content.Texts?.filter((text) => text.selector?.includes('specContent')).map(blockTextPredicate);

  if (!categories || !descriptions) {
    return [];
  }
  return categories?.reduce<Array<TechnicalSpecification>>((acc, category, index) => {
    const specification = { category, description: [descriptions[index]] };
    return [...acc, specification];
  }, []);
}
