import { Image } from '@remarkable/ark-web';

const MyScriptLogo = () => (
  <p className="text-14 md:text-16 w-full flex items-center">
    Handwriting Conversion feature powered by{' '}
    <span className="inline-flex h-16 w-[71px] mb-[-3px] relative">
      <Image src="/images/myScript.svg" alt="MyScript, logo" />
    </span>
  </p>
);

export default MyScriptLogo;
