import classNames from 'classnames';
import React, { ReactNode } from 'react';

import SpecificationRows from './SpecificationRows';

export type TechnicalSpecification = {
  category: string;
  description: Array<ReactNode>;
};

interface Props {
  headerText?: string | null;
  headerFontSize?: string;
  specifications: Array<TechnicalSpecification>;
}

export const TechnicalSpecificationList: React.FC<Props> = ({ headerText, headerFontSize, specifications }) => {
  return (
    <div className="mx-auto my-0 w-full flex justify-around">
      <div>
        <h2
          className={classNames(
            'text-center mx-0 mb-56 mt-0 text-grayscale-gray-50 px-24',
            headerFontSize ?? 'text-54 md:text-68'
          )}
        >
          {headerText}
        </h2>
        <div className="mt-0 mx-24 max-w-[780px]">
          <SpecificationRows specifications={specifications} />
        </div>
      </div>
    </div>
  );
};
