import { ImageProps } from '@remarkable/ark-web';
import classNames from 'classnames';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { ReactElement, useState } from 'react';

interface Props {
  imgSizeStyle: string;
  /**
   * @param sliderColor
   * @description example usage "bg-dark border-dark",
   * bg-x changes slider circle,
   * border-x, changes vertical line
   */
  sliderColor?: string;
  caretColor?: string;
  backgroundColor?: string;
  sliderChanged?: (position: number) => void;
  backgroundImage: ReactElement<ImageProps>;
  foregroundImage: ReactElement<ImageProps>;
}

const ImageSlider = ({
  imgSizeStyle,
  sliderChanged,
  sliderColor = 'bg-brand-green-700',
  caretColor = 'white',
  backgroundColor = 'bg-grayscale-gray-700',
  backgroundImage,
  foregroundImage,
}: Props): JSX.Element | null => {
  const [foregroundImageWidth, setForegroundImageWidth] = useState(50);
  const [sliderButtonLeftPosition, setSliderButtonLeftPosition] = useState(50);

  const borderColor = sliderColor === 'bg-brand-green-700' ? 'border-brand-green-700' : sliderColor;
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sliderPosition = parseInt(e.target.value);

    setForegroundImageWidth(sliderPosition);
    setSliderButtonLeftPosition(sliderPosition);
    if (sliderChanged) sliderChanged(sliderPosition);
  };
  return (
    <div className={classNames('relative', imgSizeStyle)}>
      <div id="background-img" className="absolute top-0 left-0 z-0 w-full h-full bg-cover">
        {backgroundImage}
      </div>
      <div
        id="foreground-img"
        className={classNames(
          'absolute top-0 left-0 h-full z-10 bg-cover border-0 border-r-thick border-solid overflow-hidden',
          backgroundColor,
          borderColor
        )}
        style={{
          width: `${foregroundImageWidth}%`,
        }}
      >
        <div className="w-screen h-full">{foregroundImage}</div>
      </div>
      {/* Slider */}
      <input
        type="range"
        min="1"
        max="100"
        value={sliderButtonLeftPosition}
        onChange={(e) => handleSliderChange(e)}
        className="absolute left-0 z-20 w-full h-full m-0 bg-transparent outline-none appearance-none cursor-pointer"
      />

      {/* Slider button */}
      <div
        className={`pointer-events-none flex justify-center items-center absolute w-40 h-40 rounded-full z-20 ${sliderColor}`}
        style={{
          left: `calc(${sliderButtonLeftPosition.toString()}% - 22px)`,
          top: 'calc(50% - 22px)',
        }}
      >
        <CaretLeft size={16} color={caretColor} />
        <CaretRight size={16} color={caretColor} />
      </div>
    </div>
  );
};

export default ImageSlider;
