import React from 'react';

export type Variant =
  | 'Underline_2'
  | 'Underline_3'
  | 'Underline_4'
  | 'Underline_7'
  | 'Wavy_underline'
  | 'scribble_line';

type Props = {
  variant: Variant;
  markupHidden?: boolean;
  color?: 'white' | 'black';
  percentageWidth?: number;
  rotation?: number;
  translation?: {
    x?: number | string;
    y?: number | string;
  };
  invert?: boolean;
  scale?: { x?: number; y?: number };
};

const HandDrawnMarkup: React.FunctionComponent<Props> = ({
  variant,
  markupHidden = false,
  color = 'white',
  percentageWidth = 100,
  rotation,
  translation,
  children,
  invert,
  scale,
}) => {
  const fileName = `${variant}_${color}.svg`;

  if (markupHidden) {
    return <span className="inline">{children}</span>;
  }

  return (
    <span
      className="relative inline-block"
      style={
        {
          '--imgUrl': `url('/svgs/${fileName}')`,
          '--width': `${percentageWidth}%`,
          '--x': translation?.x ? (typeof translation.x === 'string' ? translation.x : `${translation.x}%`) : null,
          '--y': translation?.y ? (typeof translation.y === 'string' ? translation.y : `${translation.y}%`) : null,
          '--rot': rotation ? `${rotation}deg` : null,
          '--invert': invert ? '-1,1' : '1,1',
          '--scale': scale ? `${scale.x ?? 1},${scale.y ?? 1}` : '1,1',
        } as React.CSSProperties
      }
    >
      <div
        className="absolute"
        style={{
          content: 'var(--imgUrl)',
          width: 'var(--width)',
          transform:
            'translate(var(--x, 0), var(--y, 0)) rotate(var(--rot, 0)) scale(var(--invert)) scale(var(--scale))',
        }}
      />
      {children}
    </span>
  );
};

export default HandDrawnMarkup;
