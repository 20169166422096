import { SanityImageObject } from 'src/components/Image/SanityImage';
import { SanityProductImages } from 'src/queries/groq/productImages';
import { ArrayElement } from 'src/typings/arrayElement';
import { SanityBlockContent } from 'src/typings/runtime-typecheck/sanityBlock';
import { SanityFrequentlyAskedQuestions } from 'src/typings/runtime-typecheck/sanityFrequentlyAskedQuestions';
import { SanityProductImageSchemaType } from 'src/typings/runtime-typecheck/sanityImage';
import { SanityImage, SanityLocalizedTextsParagraph } from 'src/typings/sanityTypes';
import {
  WebsiteContent,
  WebsiteSectionContent,
  WebsiteSectionContentWithImages,
  WebsiteSectionContentWithTexts,
  WebsiteSectionContentWithUrls,
} from 'src/typings/websiteContent';
import { isOnServer } from 'src/utils/isOnServer';

// Bare-bones service function
const _findBySelector: <T extends { selector?: string | null }>(
  arr: T[] | null | undefined,
  selector: string
) => T | undefined = (arr, selector) => {
  if (!arr || !selector) {
    return undefined;
  }
  return arr.find((_) => _.selector === selector);
};

// Usable function that produces warning when expected content isn't found
export const findBySelector: typeof _findBySelector = (arr, selector) => {
  const selected = _findBySelector(arr, selector);
  if (selected === undefined && isOnServer()) {
    console.warn('findBySelector returned undefined for selector ', selector);
  }
  return selected;
};

// Bare-bones service function
export const _findBySelectorId: <T extends { selectorId?: string | null }>(
  arr: T[],
  selectorId: string
) => T | undefined = (collection, selectorId) => {
  if (!collection || !selectorId) {
    return undefined;
  }
  return collection.find((_) => _?.selectorId === selectorId);
};

export const findBySelectorId: typeof _findBySelectorId = (arr, selectorId) => {
  const selected = _findBySelectorId(arr, selectorId);
  if (selected === undefined && isOnServer()) {
    console.warn('findBySelectorId returned undefined for selectorId ', selectorId);
  }
  return selected;
};

export const getSectionContentBySelectorId: <T extends WebsiteSectionContent>(
  data: WebsiteContent<T>,
  id: string
) => T | undefined = (data, id) => {
  const content = _findBySelectorId(data.content, id);
  if (content === undefined && isOnServer()) {
    console.warn('getSectionContentBySelectorId returned undefined for content id ', id);
  }
  return content;
};

export const extractParagraphsBySelector: <T extends WebsiteSectionContentWithTexts>(
  sectionContent: T | undefined,
  selector: string
) => ArrayElement<T['Texts']>['paragraphs'] | null = (sectionContent, selector) => {
  if (!sectionContent) return null;
  const textFound = sectionContent?.Texts.find((text) => text.selector === selector);
  return textFound === undefined ? null : textFound.paragraphs;
};

export const extractLocalizedParagraphsBySelector = (
  Texts: SanityLocalizedTextsParagraph[] | undefined,
  selector: string,
  country: string
) => {
  if (!Texts) return null;
  const textFound = (Texts as SanityLocalizedTextsParagraph[]).find((text) => text.selector === selector);

  if (textFound) {
    const customParagraphForCountryExists = textFound.customParagraphs?.filter((customParagraph) => {
      return customParagraph.countries.includes(country);
    });

    if (customParagraphForCountryExists?.length) {
      return customParagraphForCountryExists[0].customParagraphs[0].children[0].text;
    } else if (textFound.paragraphs) {
      return textFound.paragraphs[0].children[0].text;
    }
  }
  return null;
};

// This extracts the genericImage and reshapes the data so it fits with the sanity image components
// TODO: here we cast the io-ts type to the TS type from the image component, and we would prefer to only use the io-ts type.
export const extractGenericImageBySelector: (
  sectionContent: WebsiteSectionContentWithImages,
  selector: string
) => SanityProductImageSchemaType = (sectionContent, selector) => {
  const image = sectionContent.images.find((img) => img.selector === selector);
  // TODO: sentry? should we throw here?
  if (!image) {
    throw new Error(`Did not find image with selector: ${selector}`);
  }
  return {
    alt: image.alt,
    bgColor: image.bgColor,
    ...image.image,
  } as SanityProductImageSchemaType; // TODO: fix this, we don't want to use casting
};

// returns the first matching element, not all matching
export const extractProductImageBySelector: (images: SanityImageObject[], selector: string) => SanityImageObject = (
  images,
  selector
) => {
  const imageObj = images.find((img) => img.selector === selector);
  // TODO: sentry? should we throw here?
  if (!imageObj) {
    throw new Error(`Did not find image with selector: ${selector}`);
  }
  return imageObj;
};

// TODO: this doesnt need to be so spesific, can do node array instead of images and key to find
export const extractProductImagesBySku: (
  productImages: SanityProductImages,
  sku: string,
  bgColor?: string
) => ArrayElement<SanityProductImages> = (productImages, sku) => {
  const productImage = productImages.find((node) => node.sku === sku);
  // TODO: sentry? should we throw here?
  if (!productImage) {
    throw new Error(`Did not find image with sku: ${sku}`);
  }

  return productImage;
};

/**
 * @deprecated use extractGenericImageBySelector instead to use new image components, this uses old gatsby image types
 * TODO: kill this and the SanityImage type if we can
 * @param sectionContent
 * @param selector
 */
export const extractImageBySelector: (
  sectionContent: WebsiteSectionContentWithImages,
  selector: string
) => SanityImage = (sectionContent, selector) => {
  const imageFound = sectionContent.images.find((img) => img.selector === selector);
  if (imageFound === undefined) {
    throw new Error(`Did not find image with selector: ${selector}`);
  }
  return {
    ...imageFound,
    alt: imageFound.alt as any,
    selector: imageFound.selector as any,
    image: {
      ...imageFound.image,
      asset: {
        url: imageFound.image.asset.url || undefined,
      },
    },
  };
};

// TODO: make images proper type and not 'any'
export const extractIconBySelector: (images: any[], selector: string) => [string, string] = (images, selector) => {
  const imageFound = images.find((img) => img.selector === selector);
  if (imageFound === undefined) {
    throw new Error(`Did not find image with selector: ${selector}`);
  } else if (!imageFound.image.asset.url) {
    throw new Error(
      `Did not find url in image with selector: ${selector}. Make sure you extract 'url' of the asset object in the data fetching query.`
    );
  }
  return [imageFound.image.asset.url, imageFound.alt];
};

export const extractImageUrlBySelector: (
  sectionContent: WebsiteSectionContentWithImages,
  selector: string
) => string = (sectionContent, selector) => {
  const imageFound = sectionContent.images.find((img) => img.selector === selector);
  if (imageFound === undefined) {
    throw new Error(`Did not find image with selector: ${selector}`);
  }
  return imageFound.image.asset.url;
};

export const getParagraphTextFromSection: <T extends WebsiteSectionContentWithTexts>(
  contentItem: T,
  selectorId: string
) => string = (contentItem, selectorId) => {
  const paragraph = extractParagraphsBySelector(contentItem, selectorId);
  if (paragraph) {
    return paragraph[0].children[0].text;
  } else {
    return '';
  }
};

export function getUrlFromSelectorId(section: WebsiteSectionContentWithUrls, selectorId: string) {
  if (!section) return '';
  const urlFound = section.urls?.find((text) => text.selector === selectorId);
  return urlFound === undefined ? '' : urlFound.url;
}

export const getParagraphTextFromWebsiteContent: (
  sectionContent: WebsiteSectionContentWithTexts,
  selectorId: string
) => string = (sectionContent, selectorId) => {
  if (isOnServer() && sectionContent.Texts === undefined) {
    console.warn('Encountered undefined content text in getParagraphTextFromWebsiteContent for id ', selectorId);
  }
  if (sectionContent.Texts) {
    const textFound = sectionContent.Texts.find((text) => text.selector === selectorId);
    return textFound?.paragraphs?.[0]?.children?.[0]?.text ?? '';
  }
  return '';
};

export const _getParagraphTextListFromWebsiteContentByPredicate = (
  sectionContent: WebsiteSectionContentWithTexts,
  predicate: (selector) => boolean
) => {
  return sectionContent.Texts.filter((text) => predicate(text.selector)).map((_) => {
    return _?.paragraphs?.[0]?.children?.[0]?.text;
  });
};

export const getParagraphTextListFromWebsiteContent = (
  sectionContent: WebsiteSectionContentWithTexts,
  selectorSubstring: string
) => {
  return _getParagraphTextListFromWebsiteContentByPredicate(sectionContent, (selector) =>
    selector.includes(selectorSubstring)
  );
};

export const mapAnswersFromSanityFaqItem = (
  faq: SanityFrequentlyAskedQuestions,
  country: string
): { question: string; answer: SanityBlockContent[] } => {
  let answer: SanityBlockContent[] = [];
  if (faq.deafaultAnswer) {
    answer = faq.deafaultAnswer.map((item) => item);
  } else if (faq.answer) {
    answer = faq.answer
      .filter((item) => item?.countries.includes(country) ?? false)
      .flatMap((item) => (item.answer ? item.answer.map((item) => item) : []));
  }

  return { question: faq.question, answer };
};
