import { TechnicalSpecification } from 'src/components/TechnicalSpecificationsList/TechnicalSpecificationsList';

import DividerLine from './DividerLine';

interface Props {
  specifications: Array<TechnicalSpecification>;
}

const SpecificationRows = ({ specifications }: Props): JSX.Element => {
  return (
    <>
      {specifications.map((specification) => {
        return (
          <div key={specification.category}>
            <DividerLine />

            <div className="grid grid-cols-3 text-light whitespace-pre-wrap">
              <h3 className="max-w-[200px] col-span-1 text-20 md:text-24 font-serif m-0 pr-8 md:pr-0">
                {specification.category}
              </h3>
              <span className="col-span-2 text-14 md:text-16">{specification.description}</span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SpecificationRows;
